import {Link, useLocation} from '@remix-run/react'
import {ChevronRightIcon} from '~/components/icons'

export function FooterCTA() {
  const {pathname} = useLocation()
  if (['help', 'about'].includes(pathname.replace('/', ''))) {
    return null
  }

  return (
    <div className="border-radix-mauve4 from-radix-mauve2 to-radix-mauve-black border-t bg-linear-to-b py-32">
      <div className="mx-4 flex max-w-7xl flex-col items-start gap-8 sm:mx-auto sm:flex-row sm:items-center">
        <div className="font-display text-radix-mauve12 text-left text-3xl font-bold tracking-tight sm:text-left sm:text-4xl">
          Your builds have never been this quick.
        </div>

        <Link
          to="/start"
          className="border-radix-grass7 bg-radix-grass3 text-radix-grass11 hover:border-radix-grass8 flex items-center gap-2 rounded-lg border px-4 py-3 text-sm leading-none font-bold whitespace-nowrap"
        >
          <span>Start building</span>
          <ChevronRightIcon className="" />
        </Link>
      </div>
    </div>
  )
}
